import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "./travelspiritvluchten.scss";
import vluchtenpage from "../assets/images/vluchten.svg";
import kofferLabel from '../assets/images/labels/flight-label.png';
import { Link } from "gatsby";


export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Travelspirit Vluchten (Flights)" urlParams="travelspiritvluchten" />
      <div>
        <div className="flight-travel-page-full flight-back-office-section-first">
          <div className="flight-travel-page-container">
            <div className="flight-back-office-content">
              <h6>Beheer vluchten </h6>
              <div className="flight-back-office-wrap">
                <h1>VLUCHTEN<br /></h1>
                <img alt="vluchten label" src={kofferLabel} />
              </div>
              <p>Er zijn uitgebreide opties voor het beheren van vluchten, zoals vluchtschema's, passagiergegevens en prijzen. De integraties met Amadeus, Galileo, Sabre en meer kunnen <strong>PNR-gegevens automatisch worden ingelezen en verwerkt in een Travelplan.</strong> Voor inkoopfacturatie kun je gebruikmaken van BSP matching of de Airtrade koppeling.</p>
              <ul className="flight-travel-page-container-ul">
                <li>Importeren vluchten (GDS, Airtrade, Consolidair)</li>
                <li>Afrekening inlezen (IATA HOT, Airtrade)</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flight-travel-page-full flight-back-office-section-two" id="import-flight">
          <div className="flight-travel-page-container">
            <div className="flight-all-travel-common-box-main">
              <div className="flight-all-travel-common-box-left">
                <h3>
                  <img alt="" src={vluchtenpage} />
                </h3>
              </div>
              <div className="flight-all-travel-common-box-right">
                <h3>Importeren vluchten</h3>
                <p>TravelSpirit heeft een koppeling met alle <strong>grote GDS'en.</strong> Dit zijn o.a Amadeus, Galileo,Sabre en Worldspan.</p>
                <p>Werk je met een <strong>consolidator?</strong> De AirTrade <strong>Farefinder</strong> werkt ook goed met TravelSpirit!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flight-travel-page-full flight-crm-office-section" id="read-settlement">
          <div className="flight-travel-page-container">
            <div className="flight-all-travel-common-box-main">
              <div className="flight-all-travel-common-box-left">
                <h3>“Zo weet je zeker dat elke afrekening 100% klopt!.”</h3>
              </div>
              <div className="flight-all-travel-common-box-right">
                <h3>Afrekening inlezen</h3>
                <p>Ben je zelf IATA en ontvang je afrekeningen van de BSP, dan kun je de inkoopfacturen uitbreiden met een <strong>automatische BSP matching</strong> aan je inkooporders.</p>
                <p>Ook de <strong>verzamelfacturen</strong> van Airtrade kunnen <strong>automatisch</strong> worden <strong>gematched</strong> met de <strong>inkoopregels</strong> in TravelSpirit. Zo weet je zeker dat elke afrekening 100% klopt!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flight-travel-page-full flight-kost-section">
          <div className="flight-travel-page-container">
            <div className="flight-kost-section-content">
              <h2>Wat kost de Vluchten?</h2>
              <p>Voor elke type reisorganisatie bieden wij een passend abonnement inclusief de Vluchten</p>
              <Link key='flight-pricing' to={`/pricing`}>
                Bekijk abonnementen <span>&gt;</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="flight-travel-page-full flight-benieuwd-section">
          <div className="flight-travel-page-container">
            <div className="flight-benieuwd-section-content">
              <h2>Benieuwd wat TravelSpirit voor jou kan betekenen?</h2>
              <p>Wij gaan graag met je in gesprek om de mogelijkheden te bespreken om je verder te helpen.</p>
              <div className="flight-benieuwd-btn">
                <div className="benieuwd-btn-item">
                    <Link key='flight-demo' to={`/demo`} className="btn btn-primary demo-implement">
                        Demo inplannen
                    </Link>
                    <span className="benieuwd-btn-item-text">Online sessie van 30 minuten</span>
                </div>
                <div className="benieuwd-btn-item">
                    <Link key='flight-verkennend' to={`/demo-afspraak`} className="btn btn-primary verkennend-implement">
                        Verkennend gesprek
                    </Link>
                    <span className="benieuwd-btn-item-text">Stel online al jouw vragen</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
